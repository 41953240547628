import { graphql } from "gatsby"
import React from "react"
import { FeaturesIconsSection } from "@solublestudio/bdeo-design-system"

export default FeaturesIconsSection

export const query = graphql`
  fragment Feature on DatoCmsFeature {
    icon {
      file {
        publicURL
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
    title
  }

  fragment DatoCmsFeaturesIconsSection on DatoCmsFeaturesIconsSection {
    title
    titleTag
    featureIconsTitle
    subtitle
    features {
      ...Feature
    }
    light
  }
`
