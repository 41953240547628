import { graphql } from "gatsby"
import React from "react"
import { ProductSection } from "@solublestudio/bdeo-design-system"

export default ({ products, ...restProps }) => {
  const thisProducts =
    products?.length > 0
      ? products.map((product) => ({
          ...product,
          ...(!!product.video?.file && {
            video: {
              src: product.video.file.publicURL,
              extension: product.video.file.extension,
            },
          }),
        }))
      : []

  return <ProductSection {...restProps} products={thisProducts} />
}

export const query = graphql`
  fragment Product on DatoCmsProduct {
    title
    description
    ctas {
      ...Button
    }
    image {
      file {
        publicURL
        childImageSharp {
          fixed(width: 526, height: 458) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
    imageLeft
    video {
      file {
        publicURL
        extension
      }
    }
    videoImage {
      file {
        childImageSharp {
          resize(width: 960) {
            src
          }
        }
      }
    }
  }

  fragment DatoCmsProductSection on DatoCmsProductSection {
    title
    titleTag
    titleGradient {
      ...TGradient
    }
    products {
      ...Product
    }
  }
`
