import { graphql } from "gatsby"
import React from "react"
import { ServiceButtonSection } from "@solublestudio/bdeo-design-system"

export default ServiceButtonSection

export const query = graphql`
  fragment SButton on DatoCmsServiceButton {
    preTitle
    title
    cta {
      ...Button
    }
  }
  fragment DatoCmsServiceButtonSection on DatoCmsServiceButtonSection {
    serviceButtons {
      ...SButton
    }
  }
`
