import { graphql } from "gatsby"
import React from "react"
import { BenefitsSection } from "@solublestudio/bdeo-design-system"

export default BenefitsSection

export const query = graphql`
  fragment Benefit on DatoCmsBenefit {
    title
    description
  }

  fragment DatoCmsBeneficsSection on DatoCmsBeneficsSection {
    title
    subtitle
    titleTag
    benefits {
      ...Benefit
    }
  }
`
