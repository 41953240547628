import { graphql } from "gatsby"
import React from "react"
import { TextHeroSection } from "@solublestudio/bdeo-design-system"

export default TextHeroSection
export const query = graphql`
  fragment DatoCmsTextHeroSection on DatoCmsTextHeroSection {
    title
    titleTag
    description
    background {
      file {
        publicURL
        childImageSharp {
          fixed(width: 2880, height: 1020) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }
`
