import React from "react"
import { CardListSection } from "@solublestudio/bdeo-design-system"
import { graphql } from "gatsby"
import { useTranslate } from "@solublestudio/gatsby-theme-soluble-source"

export default function CarouselEventsSection(props: any) {
  const _ = useTranslate()
  return (
    <CardListSection
      title={props.title}
      titleTag="h2"
      titleCardsTag="h3"
      xsCardColumns={12}
      lgCardColumns={12}
      xxlCardColumns={12}
      isCarousel={true}
      cards={props.events.map((e: any) => ({
        ...e,
        ctaLabel: _["resource.event.label"].text,
        pretitle: e.date,
        horizontal: { xs: false, lg: true },
        kind: "event",
        detached: true,
      }))}
      cta={props.cta}
      dark={true}
    />
  )
}

export const query = graphql`
  fragment DatoCmsCarouselEventsSection on DatoCmsCarouselEventsSection {
    title
    titleTag
    cta {
      ...Button
    }
    events {
      ...EventItem
    }
  }
`
