import { graphql } from "gatsby"
import React from "react"
import { QuoteSection } from "@solublestudio/bdeo-design-system"

export default QuoteSection

export const query = graphql`
  fragment DatoCmsQuoteSection on DatoCmsQuoteSection {
    text
    logo {
      file {
        publicURL
        childImageSharp {
          fixed(height: 128) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
    name
    role
  }
`
