import { graphql } from "gatsby"
import React from "react"
import { IntroTextSection } from "@solublestudio/bdeo-design-system"

export default IntroTextSection

export const query = graphql`
  fragment DatoCmsIntroTextSection on DatoCmsIntroTextSection {
    description
  }
`
