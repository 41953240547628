import { graphql } from "gatsby"
import React from "react"
import { ProcessSection } from "@solublestudio/bdeo-design-system"
export default ProcessSection

export const query = graphql`
  fragment Step on DatoCmsStep {
    title
    titleNode {
      contentWithoutWrapper
    }
    number
    description
    icon {
      file {
        publicURL
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }

  fragment DatoCmsProcessSection on DatoCmsProcessSection {
    steps {
      ...Step
    }
  }
`
