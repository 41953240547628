import React from "react"
import { CardListSection } from "@solublestudio/bdeo-design-system"
import { graphql } from "gatsby"
import { useTranslate } from "@solublestudio/gatsby-theme-soluble-source"

export default function WhitePaperFullSection(props: any) {
  return (
    <CardListSection
      xsCardColumns={12}
      lgCardColumns={4}
      xxlCardColumns={4}
      cards={props.items.map((post: any) => ({
        ...post,
        kind: "whitepaper",
      }))}
    />
  )
}

export const query = graphql`
  fragment DatoCmsWhitePaperFullSection on DatoCmsWhitePaperFullSection {
    items {
      ...HubspotLanding
    }
  }
`
