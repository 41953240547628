import React from "react"
import { CardListSection } from "@solublestudio/bdeo-design-system"
import { graphql } from "gatsby"

export default function SucessStoriesShortSection(props: any) {
  return (
    <CardListSection
      title={props.title}
      titleTag="h2"
      titleCardsTag="h3"
      xsCardColumns={12}
      lgCardColumns={4}
      xxlCardColumns={3}
      cards={
        props?.items.map((wp: any) => ({
          ...wp,
          kind: "logo",
          cardImageStyle: "contain",
        })) ?? []
      }
      cta={props.cta}
    />
  )
}

export const query = graphql`
  fragment DatoCmsSuccessStoriesShortSection on DatoCmsSuccessStoriesShortSection {
    title
    titleTag
    cta {
      ...Button
    }
    items {
      ...HubspotLanding
    }
  }
`
