import { graphql } from "gatsby"
import React from "react"
import { CardListSection } from "@solublestudio/bdeo-design-system"

function UseCasesListSection({ useCasesCards, ...props }: any) {
  return (
    <CardListSection
      cards={useCasesCards}
      xsCardColumns={12}
      lgCardColumns={4}
      xxlCardColumns={4}
      {...props}
    />
  )
}
export default UseCasesListSection

export const query = graphql`
  fragment UseCasesCard on DatoCmsUseCasesCard {
    image {
      file {
        publicURL
        childImageSharp {
          fixed(width: 327, height: 172) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
    title
    description
    cta {
      ...Button
    }
  }

  fragment DatoCmsUseCasesListSection on DatoCmsUseCasesListSection {
    useCasesCards {
      ...UseCasesCard
    }
  }
`
