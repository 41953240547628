import { graphql } from "gatsby"
import React from "react"
import { InternalHero } from "@solublestudio/bdeo-design-system"

export default InternalHero

export const query = graphql`
  fragment DatoCmsInternalHero on DatoCmsInternalHero {
    title
    titleTag
    description
    pictures {
      file {
        publicURL
        childImageSharp {
          fixed(width: 550, height: 550) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }
`
