import React from "react"
import { CardListSection } from "@solublestudio/bdeo-design-system"
import { graphql } from "gatsby"
import { useTranslate } from "@solublestudio/gatsby-theme-soluble-source"

export default function MediaSection(props: any) {
  const _ = useTranslate()

  return (
    <CardListSection
      title={props.title}
      titleTag="h2"
      titleCardsTag="h3"
      xsCardColumns={12}
      lgCardColumns={4}
      xxlCardColumns={4}
      cards={props.mediaItems.map((mi: any) => ({
        ...mi,
        ctaLabel: _["media.readmore.label"].text,
        externalLink: mi.url,
        kind: "logo",
        cardImageStyle: "contain",
      }))}
      dark={true}
    />
  )
}

export const query = graphql`
  fragment MediaItem on DatoCmsMediaItem {
    description
    image {
      file {
        publicURL
        childImageSharp {
          resize(width: 300) {
            src
          }
        }
      }
    }
    url
    downloadLink {
      file {
        publicURL
      }
      basename
    }
  }

  fragment DatoCmsMediaSection on DatoCmsMediaSection {
    title
    titleTag
    mediaItems {
      ...MediaItem
    }
  }
`
