import { graphql } from "gatsby"
import React from "react"
import { CtaSectionAlt } from "@solublestudio/bdeo-design-system"

export default CtaSectionAlt

export const query = graphql`
  fragment DatoCmsCtaSectionAlt on DatoCmsCtaSectionAlt {
    preTitle
    titleGradient {
      ...TGradient
    }
    cta {
      ...Button
    }
  }
`
