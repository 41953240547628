import React from "react"
import { graphql } from "gatsby"
import { IframeSection } from "@solublestudio/bdeo-design-system"

export default IframeSection

export const query = graphql`
  fragment DatoCmsIframeSection on DatoCmsIframeSection {
    src
  }
`
