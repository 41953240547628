import React from "react"
import { CardListSection } from "@solublestudio/bdeo-design-system"
import { graphql } from "gatsby"
import { useTranslate } from "@solublestudio/gatsby-theme-soluble-source"

export default function NextEventsSection(props: any) {
  const _ = useTranslate()
  const sortedEvents = props.events.sort((a: { dateRaw: string }, b: { dateRaw: string }) => {
    return new Date(a.dateRaw).getTime() - new Date(b.dateRaw).getTime()
  });
  return (
    <CardListSection
      title={props.title}
      titleTag={props.titleTag}
      xsCardColumns={12}
      lgCardColumns={12}
      xxlCardColumns={12}
      thisSectionType="NextEventsSection"
      previousSectionType={props.previousSectionType}
      cards={sortedEvents.map((e: any) => ({
        ...e,
        ctaLabel: _["resource.event.label"].text,
        pretitle: e.date,
        horizontal: { xs: false, lg: true },
        kind: "event",
        detached: true,
      }))}
    />
  )
}

export const query = graphql`
  fragment DatoCmsNextEventsSection on DatoCmsNextEventsSection {
    title
    titleTag
    events {
      ...EventItem
    }
  }
`
