import { graphql } from "gatsby"
import React from "react"
import { HTMLTextSection } from "@solublestudio/bdeo-design-system"

export default HTMLTextSection

export const query = graphql`
  fragment DatoCmsTextSection on DatoCmsTextSection {
    content
  }
`
