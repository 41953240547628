import { graphql } from "gatsby"
import React from "react"
import { CardListSection } from "@solublestudio/bdeo-design-system"

export default function index(props: any) {
  return (
    <CardListSection
      title={props.title}
      titleTag="h2"
      titleCardsTag="h3"
      xsCardColumns={12}
      lgCardColumns={6}
      xxlCardColumns={6}
      cards={props.blogPosts.map((b: any) => ({
        ...b,
        horizontal: { xs: false, lg: true },
        pretitle: b.tags.map((t: any) => t.title).toString(),
        kind: "blog",
      }))}
      cta={props.cta}
    />
  )
}

export const query = graphql`
  fragment DatoCmsBlogShortSection on DatoCmsBlogShortSection {
    title
    titleTag
    cta {
      ...Button
    }
    blogPosts {
      ...PostItem
    }
  }
`
