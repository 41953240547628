import { graphql } from "gatsby"
import React from "react"
import { FeatureSection } from "@solublestudio/bdeo-design-system"

export default FeatureSection

export const query = graphql`
  fragment DatoCmsFeatureSection on DatoCmsFeatureSection {
    titleGradient {
      ...TGradient
    }
    description
    dark
    cta {
      ...Button
    }
    hexagonSteps
  }
`
