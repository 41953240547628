import React from "react"
import { graphql } from "gatsby"

import { FormSection } from "@solublestudio/bdeo-design-system"

export default (props) => {
  return (
    <FormSection
      {...props}
      {...(!!props.video?.file && {
        video: {
          src: props.video.file.publicURL,
          extension: props.video.file.extension,
        },
      })}
    />
  )
}

export const query = graphql`
  fragment DatoCmsFormSection on DatoCmsFormSection {
    hubspotFormId
    hubspotPortalId
    titleGradient {
      ...TGradient
    }
    subtitle
    video {
      file {
        publicURL
        extension
      }
    }
    videoImage {
      file {
        childImageSharp {
          resize(width: 780) {
            src
          }
        }
      }
    }
  }
`
