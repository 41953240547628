import { graphql } from "gatsby"
import React from "react"
import { TitleSection } from "@solublestudio/bdeo-design-system"

export default TitleSection

export const query = graphql`
  fragment DatoCmsTitleSection on DatoCmsTitleSection {
    title
    subtitle
  }
`
