import { graphql } from "gatsby"
import React from "react"
import { HeroSectionAlt } from "@solublestudio/bdeo-design-system"

export default (props) => {
  return (
    <HeroSectionAlt
      {...props}
      {...(!!props.video?.file && {
        video: {
          src: props.video.file.publicURL,
          extension: props.video.file.extension,
        },
      })}
    />
  )
}

export const query = graphql`
  fragment DatoCmsHeroSectionAlt on DatoCmsHeroSectionAlt {
    preTitle
    title
    titleTag
    preTitleCta {
      ...Button
    }
    description
    cta {
      ...Button
    }
    backgroundImage {
      file {
        publicURL
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    mobileBackground {
      file {
        publicURL
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    backgroundColor
    careersImage {
      file {
        publicURL
        childImageSharp {
          fixed(height: 128) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
    careersCta {
      ...Button
    }
    video {
      file {
        publicURL
        extension
      }
    }
    videoImage {
      file {
        childImageSharp {
          resize(width: 960) {
            src
          }
        }
      }
    }
  }
`
