import { graphql } from "gatsby"
import React from "react"
import { useTranslate } from "@solublestudio/gatsby-theme-soluble-source"
import { InfoCardsSection as DSInfoCardsSection } from "@solublestudio/bdeo-design-system"

export default function InfoCardsSection(props: any) {
  const translate = useTranslate()

  return (
    <DSInfoCardsSection
      downloadLabel={translate["security.download.cta.label"].text}
      {...props}
    ></DSInfoCardsSection>
  )
}

export const query = graphql`
  fragment InfoCard on DatoCmsInfoCard {
    title
    link {
      label
      externalLink
      link {
        slug
      }
    }
    downloadLink {
      file {
        publicURL
      }
      basename
    }
  }

  fragment DatoCmsInfoCardsSection on DatoCmsInfoCardsSection {
    infoCards {
      ...InfoCard
    }
  }
`
