import { graphql } from "gatsby"

import { StepsSection } from "@solublestudio/bdeo-design-system"

export default StepsSection

export const query = graphql`
  fragment DatoCmsStepsSection on DatoCmsStepsSection {
    titleGradient {
      ...TGradient
    }
    items {
      ... on DatoCmsTextImageSection {
        ...DatoCmsTextImageSection
      }
    }
    cta {
      ...Button
    }
  }
`
