import { graphql } from "gatsby"
import React, { useMemo } from "react"
import { StepSection } from "@solublestudio/bdeo-design-system"

export default function StepSectionComponent({ title, steps, description }) {
  const thisSteps = useMemo(() => {
    return steps.map((step) => ({
      ...step,
      title: step.titleNode.contentWithoutWrapper ?? step.title,
    }))
  }, [steps])

  return (
    <StepSection title={title} steps={thisSteps} description={description} />
  )
}
export const query = graphql`
  fragment DatoCmsStepSection on DatoCmsStepSection {
    title
    titleTag
    description
    steps {
      ...Step
    }
  }
`
