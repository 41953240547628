import React from "react"
import { graphql } from "gatsby"

import { CtaBannersSection as DSCtaBannersSection } from "@solublestudio/bdeo-design-system"

export default DSCtaBannersSection

export const query = graphql`
  fragment CtaBannerItem on DatoCmsCtaBanner {
    title
    titleTag
    kind
    description
    cta {
      ...Button
    }
    portalId
    formId
  }
  fragment DatoCmsCtaBannersSection on DatoCmsCtaBannersSection {
    ctaBanners {
      ...CtaBannerItem
    }
  }
`
