import { graphql } from "gatsby"
import React from "react"
import { OpenPositionSection } from "@solublestudio/bdeo-design-system"

export default OpenPositionSection

export const query = graphql`
  fragment DatoCmsOpenPositionSection on DatoCmsOpenPositionSection {
    title
    apiKeyId
  }
`
