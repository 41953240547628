import React from "react"
import { graphql } from "gatsby"

import { useTranslate } from "@solublestudio/gatsby-theme-soluble-source"

import { BlogSection as DSBlogSection } from "@solublestudio/bdeo-design-system"

export default function BlogSection(props: any) {
  const translate = useTranslate()

  return (
    <DSBlogSection
      {...props}
      defaultTagText={translate["blog.tag.default"]?.text}
      pageHref={props?.pathChild}
      firstPageHref={props?.pageSlug || props?.firstPageHref}
      currentSlug={props?.pageSlug || props?.currentSlug}
      titleTag={props?.titleTag || "h1"}
    />
  )
}

export const query = graphql`
  fragment DatoCmsBlogSectionParentPage on DatoCmsPage {
    slug
    sections {
      ... on DatoCmsBlogSection {
        title
        description
        searchURL
        tags {
          ...TagItem
        }
        posts {
          ...PostItem
        }
        pathChild
      }
      ... on DatoCmsCtaBannersSection {
        ...DatoCmsCtaBannersSection
      }
      ... on DatoCmsOtherResourcesSection {
        ...DatoCmsOtherResourcesSection
      }
      ... on DatoCmsHighlightedResourceSection {
        ...DatoCmsHighlightedResourceSection
      }
    }
  }
  fragment BlogSection on DatoCmsBlogSection {
    title
    description
    searchURL
    tags {
      ...TagItem
    }
    posts {
      ...PostItem
    }
    pathChild
    numPages
  }
`
