import { graphql } from "gatsby"
import React from "react"
import { HeroSection } from "@solublestudio/bdeo-design-system"

export default HeroSection

export const query = graphql`
  fragment DatoCmsHeroSection on DatoCmsHeroSection {
    titleGradient {
      ...TGradient
    }
    description
    cta {
      ...Button
    }
    logos {
      file {
        publicURL
        childImageSharp {
          fixed(width: 180, height: 60) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  }
`
