import { graphql } from "gatsby"
import React from "react"
import { TextImageSection } from "@solublestudio/bdeo-design-system"

export default TextImageSection

export const query = graphql`
  fragment DatoCmsTextImageSection on DatoCmsTextImageSection {
    title
    titleTag
    description
    cta {
      ...Button
    }
    image {
      file {
        publicURL
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    imageRight
    withBackground
  }
`
