/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react"

const PREFIX = `DatoCms`

interface SectionProps {
  __typename: string
}

export default function Section({
  __typename: type,
  ...props
}: SectionProps): JSX.Element {
  let SectionComponent = () => <div> -- Section {type} not found -- </div>

  switch (type) {
    case `${PREFIX}BeneficsSection`:
      SectionComponent = require("./components/BenefitsSection").default
      break
    case `${PREFIX}CtaSection`:
      SectionComponent = require("./components/CtaSection").default
      break
    case `${PREFIX}CtaSectionAlt`:
      SectionComponent = require("./components/CtaSectionAlt").default
      break
    case `${PREFIX}FeatureSection`:
      SectionComponent = require("./components/FeatureSection").default
      break
    case `${PREFIX}FeaturesIconsSection`:
      SectionComponent = require("./components/FeaturesIconsSection").default
      break
    case `${PREFIX}FeatureCategorySection`:
      SectionComponent = require("./components/FeatureCategorySection").default
      break
    case `${PREFIX}HeroSection`:
      SectionComponent = require("./components/HeroSection").default
      break
    case `${PREFIX}HeroSectionAlt`:
      SectionComponent = require("./components/HeroSectionAlt").default
      break
    case `${PREFIX}InternalHero`:
      SectionComponent = require("./components/InternalHero").default
      break
    case `${PREFIX}KnowUsSection`:
      SectionComponent = require("./components/KnowUsSection").default
      break
    case `${PREFIX}TextSection`:
      SectionComponent = require("./components/TextSection").default
      break
    case `${PREFIX}ProcessSection`:
      SectionComponent = require("./components/ProcessSection").default
      break
    case `${PREFIX}ProductSection`:
      SectionComponent = require("./components/ProductSection").default
      break
    case `${PREFIX}ServiceButtonSection`:
      SectionComponent = require("./components/ServiceButtonSection").default
      break
    case `${PREFIX}StepSection`:
      SectionComponent = require("./components/StepSection").default
      break
    case `${PREFIX}TextImageSection`:
      SectionComponent = require("./components/TextImageSection").default
      break
    case `${PREFIX}FormSection`:
      SectionComponent = require("./components/FormSection").default
      break
    case `${PREFIX}InfoCardsSection`:
      SectionComponent = require("./components/InfoCardsSection").default
      break
    case `${PREFIX}TitleSection`:
      SectionComponent = require("./components/TitleSection").default
      break
    case `${PREFIX}IntroTextSection`:
      SectionComponent = require("./components/IntroTextSection").default
      break
    case `${PREFIX}IframeSection`:
      SectionComponent = require("./components/IframeSection").default
      break
    case `${PREFIX}TextHeroSection`:
      SectionComponent = require("./components/TextHeroSection").default
      break
    case `${PREFIX}UseCasesListSection`:
      SectionComponent = require("./components/UseCasesListSection").default
      break
    case `${PREFIX}BlogShortSection`:
      SectionComponent = require("./components/BlogShortSection").default
      break
    case `${PREFIX}CarouselEventsSection`:
      SectionComponent = require("./components/CarouselEventsSection").default
      break
    case `${PREFIX}SuccessStoriesShortSection`:
      SectionComponent = require("./components/SuccessStoriesShortSection")
        .default
      break
    case `${PREFIX}WhitePaperFullSection`:
      SectionComponent = require("./components/WhitePaperFullSection").default
      break
    case `${PREFIX}WhitePaperShortSection`:
      SectionComponent = require("./components/WhitePaperShortSection").default
      break
    case `${PREFIX}SuccessStoriesFullSection`:
      SectionComponent = require("./components/SuccessStoriesFullSection")
        .default
      break
    case `${PREFIX}NextEventsSection`:
      SectionComponent = require("./components/NextEventsSection").default
      break
    case `${PREFIX}PreviusEventsSection`:
      SectionComponent = require("./components/PreviusEventsSection").default
      break
    case `${PREFIX}CtaBannersSection`:
      SectionComponent = require("./components/CtaBannersSection").default
      break
    case `${PREFIX}OtherResourcesSection`:
      SectionComponent = require("./components/OtherResourcesSection").default
      break
    case `${PREFIX}HighlightedResourceSection`:
      SectionComponent = require("./components/HighlightedResourceSection")
        .default
      break
    case `${PREFIX}BlogSection`:
      SectionComponent = require("./components/BlogSection").default
      break
    case `${PREFIX}OpenPositionSection`:
      SectionComponent = require("./components/OpenPositionSection").default
      break
    case `${PREFIX}QuoteSection`:
      SectionComponent = require("./components/QuoteSection").default
      break
    case `${PREFIX}MediaSection`:
      SectionComponent = require("./components/MediaSection").default
      break
    case `${PREFIX}WebinarFullSection`:
      SectionComponent = require("./components/WebinarFullSection").default
      break
    case `${PREFIX}WebinarShortSection`:
      SectionComponent = require("./components/WebinarShortSection").default
      break
    case `${PREFIX}StepsSection`:
      SectionComponent = require("./components/StepsSection").default
      break
    default:
      break
  }

  return <SectionComponent {...props} />
}
