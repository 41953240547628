import React from "react"
import { CardListSection } from "@solublestudio/bdeo-design-system"
import { graphql } from "gatsby"

export default function SuccessStoriesFullSection(props: any) {
  return (
    <CardListSection
      xsCardColumns={12}
      lgCardColumns={4}
      xxlCardColumns={4}
      cards={
        props?.items.map((wp: any) => ({
          ...wp,
          kind: "logo",
          cardImageStyle: "contain",
        })) ?? []
      }
    />
  )
}

export const query = graphql`
  fragment DatoCmsSuccessStoriesFullSection on DatoCmsSuccessStoriesFullSection {
    items {
      ...HubspotLanding
    }
  }
`
