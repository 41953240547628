import { graphql } from "gatsby"
import React from "react"
import { KnowUsSection } from "@solublestudio/bdeo-design-system"

export default KnowUsSection

export const query = graphql`
  fragment DatoCmsKnowUsSection on DatoCmsKnowUsSection {
    title
    titleTag
    subtitle
    features {
      ...Feature
    }
    cta {
      ...Button
    }
  }
`
