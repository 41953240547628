import { graphql } from "gatsby"
import React from "react"
import { CtaSection } from "@solublestudio/bdeo-design-system"
export default CtaSection

export const query = graphql`
  fragment DatoCmsCtaSection on DatoCmsCtaSection {
    title
    titleTag
    description
    cta {
      ...Button
    }
  }
`
