import React from "react"
import { CardListSection } from "@solublestudio/bdeo-design-system"
import { graphql } from "gatsby"

export default function WebinarShortSection(props: any) {
  return (
    <CardListSection
      title={props.title}
      titleTag="h2"
      titleCardsTag="h3"
      xsCardColumns={12}
      lgCardColumns={4}
      xxlCardColumns={3}
      cards={props.items.map((post: any) => ({
        ...post,
        kind: "webinar",
      }))}
      cta={props.cta}
      dark={false}
    />
  )
}

export const query = graphql`
  fragment DatoCmsWebinarShortSection on DatoCmsWebinarShortSection {
    title
    titleTag
    cta {
      ...Button
    }
    items {
      ...HubspotLanding
    }
  }
`
