import React, { useMemo } from "react"
import { graphql } from "gatsby"
import { useTranslate } from "@solublestudio/gatsby-theme-soluble-source"
import { HighlightedResourceSection as DSHighlightedResourceSection } from "@solublestudio/bdeo-design-system"

const KIND_RESOURCE = ["whitepaper", "successstory", "event"]

export default function HighlightedResourceSection({
  resource,
  pretitle,
}: any) {
  const translate = useTranslate()

  const indexKind = useMemo(
    () => KIND_RESOURCE.indexOf(resource?.model?.name?.toLowerCase()),
    [resource]
  )

  const isEvent = useMemo(
    () => "event" === resource?.model?.name?.toLowerCase(),
    [resource]
  )

  return (
    <DSHighlightedResourceSection
      pretitle={pretitle}
      resource={{
        ...resource,
        kind: isEvent ? "event" : null,
        detached: isEvent,
        cta:
          indexKind !== -1
            ? {
                label:
                  translate[`resource.${KIND_RESOURCE[indexKind]}.label`]?.text,
                link: {
                  slug: resource?.slug,
                },
                externalLink: resource?.slug,
              }
            : null,
      }}
    />
  )
}

export const query = graphql`
  fragment DatoCmsHighlightedResourceSection on DatoCmsHighlightedResourceSection {
    pretitle
    resource {
      ... on DatoCmsBlog {
        ...PostItem
      }
      ... on DatoCmsSuccessStory {
        ...SuccessStoryItem
      }
      ... on DatoCmsEvent {
        ...EventItem
      }
      ... on DatoCmsWhitepaper {
        ...WhitepaperItem
      }
    }
  }
`
