import { graphql } from "gatsby"
import React from "react"
import { FeatureCategorySection } from "@solublestudio/bdeo-design-system"

export default FeatureCategorySection

export const query = graphql`
  fragment FeatureCat on DatoCmsFeatureCategory {
    title
    featureIconsTitle
    subtitle
    features {
      ...Feature
    }
  }

  fragment DatoCmsFeatureCategorySection on DatoCmsFeatureCategorySection {
    title
    multipleFeaturesCategory {
      title
      featuresCategory {
        ...FeatureCat
      }
    }
    featuresCategory {
      ...FeatureCat
    }
  }
`
