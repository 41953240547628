import { graphql } from "gatsby"

import React from "react"

import { useTranslate } from "@solublestudio/gatsby-theme-soluble-source"

import { OtherResourcesSection as DSOtherResourcesSection } from "@solublestudio/bdeo-design-system"

function OtherResourcesSection(props: any) {
  const translate = useTranslate()

  return (
    <DSOtherResourcesSection
      {...props}
      label={translate["otherResources.cta.label"]?.text}
    />
  )
}

export default OtherResourcesSection

export const query = graphql`
  fragment DatoCmsOtherResourcesSection on DatoCmsOtherResourcesSection {
    title
    pages {
      title
      slug
    }
  }
`
